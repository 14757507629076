import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from "@/components/Dashboard.vue";
import Login from "@/components/Login.vue";
import WalletRequest from "@/components/WalletRequest.vue";
import Story from "@/components/Story.vue";
import Affiliate from "@/components/Affiliate.vue";
import Setting from "@/components/Setting.vue";
import Author from "@/components/authors/Author.vue";
import CommentList from "@/components/CommentList.vue";
import WalletList from "@/components/WalletList.vue";
import PrStoryRequest from "@/components/PrStoryRequest.vue";

const routes = [
    {
        path: '/',
        name: 'dashboard',
        title: 'Dashboard',
        component: Dashboard,
        meta: { requiresAuth: true }
    },
    {
        path: '/wallet_request',
        name: 'wallet_request',
        title: 'Yêu cầu nạp rút',
        component: WalletRequest,
        meta: { requiresAuth: true }
    },
    {
        path: '/story',
        name: 'story',
        title: 'Truyện',
        component: Story,
        meta: { requiresAuth: true }
    },
    {
        path: '/affiliate',
        name: 'affiliate',
        title: 'Affiliate',
        component: Affiliate,
        meta: { requiresAuth: true }
    },
    {
        path: '/author',
        name: 'author',
        title: 'Author',
        component: Author,
        meta: { requiresAuth: true }
    },
    {
        path: '/comment',
        name: 'comment',
        title: 'Comment',
        component: CommentList,
        meta: { requiresAuth: true }
    },
    {
        path: '/wallet',
        name: 'wallet',
        title: 'WalletList',
        component: WalletList,
        meta: { requiresAuth: true }
    },
    {
        path: '/pr_story_request',
        name: 'qr_story_request',
        title: 'PrStoryRequest',
        component: PrStoryRequest,
        meta: { requiresAuth: true }
    },
    {
        path: '/settings',
        name: 'setting',
        title: 'Cài đặt chung',
        component: Setting,
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    }
];

const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes
});

// Hàm kiểm tra xác thực giả
const isAuthenticated = () => {
    // Thay thế bằng logic xác thực thực sự của bạn
    return localStorage.getItem('auth') === 'true' && localStorage.getItem('is_admin') === 'true' ;
};

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuthenticated()) {
            next({ name: 'Login' });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
